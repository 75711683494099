import React, { useEffect, useRef, useState } from 'react';
import { Link } from "gatsby";

import Header from 'src/components/articles-page/Header';
import Footer from "src/components/Footer";
import useDataPosts from 'src/hooks/useDataPosts';
import { CategoryItem, Post } from 'src/components/articles-page/Posts';
import { getFilteredCategories } from "src/helpers";
import BabyNotFound from "src/images/baby_not_found.svg";



const CategoryTemplate = ({categoryName}) => {
  const initAmountPosts = 4;
  const subscribeElRef = useRef(null);
  const [currentPosts, setCurrentPosts] = useState([]);
  const [postsNumber, setPostsNumber] = useState(initAmountPosts);
  const { categories, posts: {data: posts} } = useDataPosts();

  const showMorePosts = () => {
    if (currentPosts.length > postsNumber) {
      setPostsNumber(postsNumber + 4);
    }
  }

  useEffect(()=>{
    const filtredPosts = posts?.filter(item => {
      let isFilterMatch = false;
      item?.attributes?.categories?.data.forEach(category => {
        if(category.attributes.name === categoryName) {
          isFilterMatch = true;
        }
      });
      return isFilterMatch;
    }
    );
    setPostsNumber(initAmountPosts);
    setCurrentPosts(filtredPosts);
  }, [posts, categoryName])

  useEffect(() => {
    const subscribeEl = subscribeElRef?.current;
    const observer = new IntersectionObserver(
      ([e]) => {
        const element = document.getElementById("header-categories")
        if (!e.isIntersecting) {
          element?.classList.add("show");
          element?.classList.remove("hide");
        } else {
          element?.classList.add("hide");
          element?.classList.remove("show");
        }
      },
      { threshold: [1] }
      );
      
    if (subscribeEl) {
      observer.observe(subscribeEl)
    }
    return (() => {
      observer.unobserve(subscribeEl)
    })

  }, [subscribeElRef]);

  return (
    <div className="layout-container posts-wrapper template-wrapper category-template">
      <Header categories={categories} />
      <main>
        <section className="category-template__title">
          <h1>{categoryName}</h1>
        </section>
        <section className="category-template__categories">
          <div className="container hero-articles__categories" ref={subscribeElRef}>
            {getFilteredCategories(categories)
              .map((categorie, index) => (
                <Link to={`/category/${categorie?.Name}`} key={index} className="link-route" >
                  <CategoryItem key={index} color={categorie.Color} name={categorie.Name} />
                </Link>
              )
            )}
          </div>
        </section>

        <section className="container">
          {
            currentPosts?.length !== 0 ?
              <>
                <div className="posts__articles-section">
                  { 
                    currentPosts?.slice(0, postsNumber).map(post => 
                    <Post  key={post?.id} postId={post?.id} post={post?.attributes} />)
                  }
                </div>

                {
                  currentPosts?.length > postsNumber && 
                  <div className="posts__additional">
                    <button className="btn articles__btn" type="button" onClick={showMorePosts} >
                      Show more
                    </button>
                  </div>
                }
              </>
               : 
              <>
                <div className="gap" />
                <div className="category-template__not-found">
                  <img src={BabyNotFound} alt="baby-not-found" />
                  <h2>There are no articles in this category</h2>
                </div>
              </>
          }
          
        </section>

        <div className="gap" />
        <div className="gap" />
      </main>
      <Footer />
    </div>
  );
}

export default CategoryTemplate;
